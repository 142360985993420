import classes from './style.module.scss';
import { drawPieChart } from '@optimization/sa-common';
import { EvaluatedChassisItem } from '../../utils/useEvaluatedChassisItems';
import { roundTo } from '@optimization/ssi-common';
import { useEffect, useRef } from 'react';

interface Props {
  items: EvaluatedChassisItem[];
  totalCount: number;
}

const ChassisAdaptationChart = ({ items, totalCount }: Props) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      drawPieChart({
        element: chartRef.current,
        chartItems: items.map((item) => ({ ...item, value: roundTo(item.value, 0) })),
        header: totalCount.toString(),
        subheader: 'Vehicle amount',
        innerRadius: 260,
      });
    }
  }, [items, totalCount]);

  return (
    <div className={classes['chassis-adaptation-chart']}>
      <div ref={chartRef} />
    </div>
  );
};

export default ChassisAdaptationChart;
