import { ListVehicleEnhanced } from '@optimization/sa-common';
import { useMemo } from 'react';

const useEvaluatedTrucks = (enhancedListVehicles: ListVehicleEnhanced[]) =>
  useMemo(
    () => enhancedListVehicles.filter((vehicle) => vehicle.ChassisType === 'Truck' && vehicle.ChassisNumber),
    [enhancedListVehicles],
  );

export default useEvaluatedTrucks;
