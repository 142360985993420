import { ListVehicleEnhanced } from '@optimization/sa-common';
import { useMemo } from 'react';

const useTrailerTractors = (enhancedListVehicles: ListVehicleEnhanced[]) =>
  useMemo(
    () =>
      enhancedListVehicles.filter((vehicle) => vehicle.ChassisAdaptation === 'A' && vehicle.ChassisType === 'Truck'),
    [enhancedListVehicles],
  );

export default useTrailerTractors;
