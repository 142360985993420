import sortByPrimaryOriginalAsc from './sortByPrimaryOriginalAsc';
import { dataGroups } from '../dataGroups';
import { ListVehicleEnhanced } from '@optimization/sa-common';
import { useMemo } from 'react';
import { UserSerie } from 'react-charts';

export interface DataPoint {
  primary: string;
  primaryOriginal: string | number;
  secondary: number;
  percent: number;
}

export type ChartSerie = UserSerie<DataPoint>[];

export interface ChartSeries {
  [key: string]: ChartSerie;
}

interface Props {
  evaluatedTrucks: ListVehicleEnhanced[];
}

const useChartSeries = ({ evaluatedTrucks }: Props): ChartSeries => {
  return useMemo(() => {
    let chartSeries: ChartSeries = {};

    for (const dataGroup of dataGroups) {
      const vehicleCountByPrimary = evaluatedTrucks.reduce<{
        [key: string]: { vehicleCount: number; primaryOriginal: string | number };
      }>((acc, vehicle) => {
        let primary = dataGroup.getPrimaryValue(vehicle);
        let primaryOriginal = primary;

        if (primary) {
          if (dataGroup.transformPrimaryToUnit) {
            primary = dataGroup.transformPrimaryToUnit(primary);
          }

          if (dataGroup.transformPrimaryToGroup) {
            primary = dataGroup.transformPrimaryToGroup(primary);
          }

          return {
            ...acc,
            [primary]: { vehicleCount: (acc[primary]?.vehicleCount || 0) + 1, primaryOriginal },
          };
        }

        return acc;
      }, {});

      let data: DataPoint[] = [];

      for (const primary of Object.keys(vehicleCountByPrimary)) {
        data.push({
          primary: dataGroup.getPrimaryLabel(primary),
          primaryOriginal: vehicleCountByPrimary[primary].primaryOriginal,
          secondary: vehicleCountByPrimary[primary].vehicleCount,
          percent: (vehicleCountByPrimary[primary].vehicleCount / evaluatedTrucks.length) * 100,
        });
      }

      data.sort(sortByPrimaryOriginalAsc);

      chartSeries[dataGroup.name] = [{ label: dataGroup.name, data }];
    }

    return chartSeries;
  }, [evaluatedTrucks]);
};

export default useChartSeries;
