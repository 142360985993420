import { useMemo } from 'react';

interface Props {
  rigidTrucksCount: number;
  trailerTractorsCount: number;
}

const useTotalCount = ({ rigidTrucksCount, trailerTractorsCount }: Props) =>
  useMemo(() => rigidTrucksCount + trailerTractorsCount, [rigidTrucksCount, trailerTractorsCount]);

export default useTotalCount;
