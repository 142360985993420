import classes from './style.module.scss';
import classNames from 'classnames';
import { Chip } from '@optimization/ssi-common';
import { DataGroup, dataGroups } from '../../../dataGroups';

interface Props {
  className?: string;
  selectedFilter: DataGroup;
  setSelectedFilter: React.Dispatch<React.SetStateAction<DataGroup>>;
}

const Filters = ({ className, selectedFilter, setSelectedFilter }: Props) => (
  <ul className={classNames(classes['filters'], className)}>
    {dataGroups.map((dataGroup) => (
      <Chip
        key={dataGroup.name}
        checked={dataGroup.name === selectedFilter.name}
        text={dataGroup.name}
        onClick={() => setSelectedFilter(dataGroup)}
      />
    ))}
  </ul>
);

export default Filters;
