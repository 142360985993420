import Box from '../../../utils/Box';
import classes from './style.module.scss';
import classNames from 'classnames';

interface Props {
  header: string;
  subHeader: string;
  count: number;
}

const CountBox = ({ header, subHeader, count }: Props) => (
  <Box className={classes['count-box']}>
    <div>
      <div className="tds-headline-06 tds-text-white">{header}</div>
      <div className="tds-headline-07 tds-text-grey-400 mt-spacing-4">{subHeader}</div>
    </div>
    <h2 className={classNames('tds-headline-01', classes.count)}>{count}</h2>
  </Box>
);

export default CountBox;
