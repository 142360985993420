import classes from './style.module.scss';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface Props {
  children: Array<ReactNode>;
  dataTestid?: string;
  className?: string;
}

const StatisticsGrid = ({ children, dataTestid, className }: Props) => (
  <div data-testid={dataTestid} className={classNames(classes['statistics-grid'], className)}>
    {children}
  </div>
);

export default StatisticsGrid;
