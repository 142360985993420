import classes from './style.module.scss';
import classNames from 'classnames';
import sortByPercentDesc from './utils/sortByPercentDesc';
import { ChartSerie, DataPoint } from '../utils/useChartSeries';
import { IColumn, ResponsiveTable, RowItemBase } from '@optimization/ssi-common';
import { roundTo } from '@optimization/ssi-common';
import { useCallback, useMemo, useState } from 'react';
import { UserSerie } from 'react-charts';

const ITEMS_TO_SHOW_INITIALLY = 4;

interface RowItem extends RowItemBase {
  dataPoint: DataPoint;
}

interface Props {
  className?: string;
  chartSerie: ChartSerie;
}

const StatisticsGrid = ({ className, chartSerie }: Props) => {
  const [viewAll, setViewAll] = useState(false);

  const firstChartSerie: UserSerie<DataPoint> = useMemo(() => chartSerie[0], [chartSerie]);

  const sortedData: DataPoint[] = useMemo(() => [...chartSerie[0].data].sort(sortByPercentDesc), [chartSerie]);

  const columns: IColumn<RowItem>[] = useMemo(
    () => [
      {
        columnKey: firstChartSerie.label || '',
        columnTitle: firstChartSerie.label || '',
        render: (row: RowItem) => row.dataPoint.primary,
      },
      {
        columnKey: 'chassis-count',
        columnTitle: 'No. of chassis',
        render: (row: RowItem) => row.dataPoint.secondary,
      },
      {
        columnKey: 'amount',
        columnTitle: 'Amount %',
        render: (row: RowItem) => roundTo(row.dataPoint.percent, 0),
      },
    ],
    [firstChartSerie],
  );

  const rows: RowItem[] = useMemo(
    () =>
      sortedData.slice(0, viewAll ? undefined : ITEMS_TO_SHOW_INITIALLY).map((dataPoint) => ({
        dataPoint,
      })),
    [sortedData, viewAll],
  );

  const showViewAll = useMemo(() => firstChartSerie.data.length > ITEMS_TO_SHOW_INITIALLY, [firstChartSerie]);

  const onToggleViewAll = useCallback(() => {
    setViewAll((prev) => !prev);
  }, []);

  return (
    <div>
      <ResponsiveTable<RowItem>
        className={className}
        tableTitle={firstChartSerie.label}
        modeVariant="secondary"
        columns={columns}
        rows={rows}
      />
      {showViewAll && (
        <div className="flex">
          <button
            className={classNames('reset-button-styles', 'tds-text-white', 'tds-u-ml-auto', classes['view-all'])}
            type="button"
            onClick={onToggleViewAll}
          >
            {viewAll ? 'View less' : 'View all'}
          </button>
        </div>
      )}
    </div>
  );
};

export default StatisticsGrid;
