import { ListVehicleEnhanced, PieChartItem } from '@optimization/sa-common';
import { useMemo } from 'react';

export type EvaluatedChassisItem = PieChartItem & { count: number; type: string };

interface Props {
  hasTrucks: boolean;
  rigidTrucks: ListVehicleEnhanced[];
  trailerTractors: ListVehicleEnhanced[];
  totalCount: number;
}

const useEvaluatedChassisItems = ({
  hasTrucks,
  rigidTrucks,
  trailerTractors,
  totalCount,
}: Props): EvaluatedChassisItem[] => {
  const rigidTrucksPercent = useMemo(() => (rigidTrucks.length / totalCount) * 100, [rigidTrucks.length, totalCount]);

  const trailerTractorsPercent = useMemo(
    () => (trailerTractors.length / totalCount) * 100,
    [trailerTractors.length, totalCount],
  );
  return useMemo(() => {
    const result = [];

    if (hasTrucks) {
      result.push({
        header: 'Rigid trucks',
        value: rigidTrucksPercent,
        count: rigidTrucks.length,
        color: '#6191B4',
        type: 'rigid-truck',
      });

      result.push({
        header: 'Trailer tractor',
        value: trailerTractorsPercent,
        count: trailerTractors.length,
        color: '#2B70D3',
        type: 'trailer-truck',
      });
    }

    return result;
  }, [rigidTrucksPercent, trailerTractorsPercent, hasTrucks, rigidTrucks.length, trailerTractors.length]);
};

export default useEvaluatedChassisItems;
