import classes from './style.module.scss';
import classNames from 'classnames';
import Filters from './utils/Filters';
import { AxisOptions, Chart, Datum } from 'react-charts';
import { ChartSeries, DataPoint } from '../utils/useChartSeries';
import { DataGroup, dataGroups } from '../dataGroups';
import { roundTo } from '@optimization/ssi-common';
import { useCallback, useMemo, useState } from 'react';

const defaultColors = ['#6191B4'];

interface Props {
  className?: string;
  chartSeries: ChartSeries;
}

const ChassisChart = ({ className, chartSeries }: Props) => {
  const [selectedFilter, setSelectedFilter] = useState<DataGroup>({ ...dataGroups[0] });

  const chartSerie = useMemo(() => chartSeries[selectedFilter.name], [chartSeries, selectedFilter.name]);

  const maxSecondary = useMemo(
    () => chartSerie[0].data.reduce((acc, value) => Math.max(acc, value.secondary), 0),
    [chartSerie],
  );

  const secondaryTickCount = useMemo(() => Math.min(5, maxSecondary), [maxSecondary]);

  const primaryAxis = useMemo<AxisOptions<DataPoint>>(
    () => ({
      getValue: (datum) => datum.primary,
    }),
    [],
  );

  const secondaryAxes = useMemo<AxisOptions<DataPoint>[]>(
    () => [
      {
        getValue: (datum) => datum.secondary,
        elementType: 'bar',
        min: 0,
        tickCount: secondaryTickCount,
        formatters: {
          scale: (value: number) => (Number.isInteger(value) ? roundTo(value, 0).toString() : ''),
        },
      },
    ],
    [secondaryTickCount],
  );

  const renderTooltip = useCallback(({ focusedDatum }: { focusedDatum: Datum<DataPoint> | null }) => {
    if (!focusedDatum?.tooltipGroup) {
      return;
    }

    return (
      <div className={classNames('tds-background-white', 'tds-text-black', classes['chassis-chart-tooltip'])}>
        <div className="py-spacing-8 px-spacing-8">
          <div className="flex items-center tds-detail-05">{focusedDatum.originalDatum.primary}</div>
          {focusedDatum?.tooltipGroup.map((tooltipGroup, index) => (
            <div
              key={tooltipGroup.seriesLabel}
              className={classNames(
                'flex',
                'justify-between',
                'tds-detail-07',
                index ? 'mt-spacing-4' : 'mt-spacing-8',
              )}
            >
              <div>{tooltipGroup.seriesLabel}:</div>
              <div>{tooltipGroup.secondaryValue}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }, []);

  return (
    <div className={className}>
      <Filters selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
      <div className="mt-spacing-16" style={{ minHeight: `350px` }}>
        <div className="tds-headline-07 mb-spacing-12">No. of chassis</div>
        <Chart
          options={{
            data: chartSerie,
            primaryAxis,
            memoizeSeries: true,
            secondaryAxes,
            dark: true,
            defaultColors,
            tooltip: {
              render: renderTooltip,
            },
            primaryCursor: {
              show: false,
            },
            secondaryCursor: {
              show: false,
            },
          }}
        />
      </div>
    </div>
  );
};

export default ChassisChart;
