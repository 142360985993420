import Box from '../../utils/Box';
import Chart from './Chart';
import classes from './style.module.scss';
import classNames from 'classnames';
import { EvaluatedChassisItem } from '../utils/useEvaluatedChassisItems';
import { roundTo } from '@optimization/ssi-common';
import { TdsIcon } from '@scania/tegel-react';

interface Props {
  items: EvaluatedChassisItem[];
  totalCount: number;
}

const ChassisAdaptationBox = ({ items, totalCount }: Props) => (
  <Box className={classes['chassis-adaptation-box']}>
    <Chart items={items} totalCount={totalCount} />
    <div className={classes.info}>
      <div className={classes.header}>
        <div>
          <div className="tds-body-02 tds-text-white">Chassis adaptation</div>
          <div className="tds-detail-05 tds-text-grey-400">Vehicles in solution</div>
        </div>
        <TdsIcon name="truck" size="24px" />
      </div>
      <ul className={classes.types}>
        {items.map((item) => (
          <li key={item.type}>
            <div
              style={{ backgroundColor: item.color }}
              className={classNames(classes.dot, classes[`color-${item.type}`])}
            />
            <div className={classNames('tds-detail-02', classes.header, classes[`color-${item.type}`])}>
              {item.header}
            </div>
            <div className={classNames('tds-detail-02', classes.value)}>{roundTo(item.value, 0)}%</div>
          </li>
        ))}
      </ul>
    </div>
  </Box>
);

export default ChassisAdaptationBox;
