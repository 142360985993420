import { DataPoint } from './useChartSeries';

const sortByPrimaryAsc = <Type extends DataPoint>(a: Type, b: Type) => {
  if (a.primaryOriginal < b.primaryOriginal) {
    return -1;
  }

  if (a.primaryOriginal > b.primaryOriginal) {
    return 1;
  }

  return 0;
};

export default sortByPrimaryAsc;
