import { DataPoint } from '../../utils/useChartSeries';

export const sortByPercentDesc = <Type extends DataPoint>(a: Type, b: Type) => {
  if (a.percent > b.percent) {
    return -1;
  }

  if (a.percent < b.percent) {
    return 1;
  }

  return 0;
};

export default sortByPercentDesc;
