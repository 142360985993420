import { chassisHeightTranslate, ListVehicleEnhanced } from '@optimization/sa-common';

const DAILY_DISTANCE_BUCKET_SIZE = 50;
const GTW_BUCKET_SIZE = 5;

export type DataGroupName =
  | 'Daily distance'
  | 'GTW'
  | 'Axle distance'
  | 'Cab type'
  | 'Wheel configuration'
  | 'Chassis height';

export interface DataGroup {
  name: DataGroupName;
  transformPrimaryToUnit?: (primary: number | string) => number;
  transformPrimaryToGroup?: (primary: number | string) => number;
  getPrimaryLabel: (primary: number | string) => string;
  getPrimaryValue: (vehicle: ListVehicleEnhanced) => number | string;
}

export const dataGroups: DataGroup[] = [
  {
    name: 'Daily distance',
    transformPrimaryToGroup: (primary) =>
      typeof primary === 'number' ? Math.ceil(primary / DAILY_DISTANCE_BUCKET_SIZE) * DAILY_DISTANCE_BUCKET_SIZE : 0,
    getPrimaryLabel: (primary) => `${Math.max(Number(primary) - DAILY_DISTANCE_BUCKET_SIZE, 0)} - ${primary} km`,
    getPrimaryValue: (vehicle: ListVehicleEnhanced) => vehicle.DailyOdometerMedianKm || 0,
  },
  {
    name: 'GTW',
    transformPrimaryToUnit: (primary) => (typeof primary === 'number' ? primary / 1000 : 0),
    transformPrimaryToGroup: (primary) =>
      typeof primary === 'number' ? Math.ceil(primary / GTW_BUCKET_SIZE) * GTW_BUCKET_SIZE : 0,
    getPrimaryLabel: (primary) => `${Math.max(Number(primary) - GTW_BUCKET_SIZE, 0)} - ${primary} t`,
    getPrimaryValue: (vehicle: ListVehicleEnhanced) => vehicle.AvgGtwKg || 0,
  },
  {
    name: 'Axle distance',
    transformPrimaryToGroup: (primary) => (typeof primary === 'number' ? Math.ceil(primary / 10) * 10 : 0),
    getPrimaryLabel: (primary) => `${primary} mm`,
    getPrimaryValue: (vehicle: ListVehicleEnhanced) => vehicle.AxleDistanceMm || 0,
  },
  {
    name: 'Cab type',
    getPrimaryLabel: (primary) => `${primary}`,
    getPrimaryValue: (vehicle: ListVehicleEnhanced) => vehicle.Cab || '',
  },
  {
    name: 'Wheel configuration',
    getPrimaryLabel: (primary) => `${primary}`,
    getPrimaryValue: (vehicle: ListVehicleEnhanced) => vehicle.WheelConfiguration?.toLocaleLowerCase() || '',
  },
  {
    name: 'Chassis height',
    getPrimaryLabel: (primary) => `${primary}`,
    getPrimaryValue: (vehicle: ListVehicleEnhanced) => chassisHeightTranslate[vehicle.ChassisHeight || ''] || '',
  },
];
