import ChassisAdaptationBox from './ChassisAdaptationBox';
import ChassisChart from './ChassisChart';
import CountBox from './utils/CountBox';
import HelmetTitle from 'common/components/HelmetTitle';
import InfoGrid from './utils/InfoGrid';
import StatisticsGrid from './utils/StatisticsGrid';
import StatisticsTable from './StatisticsTable';
import useChartSeries from './utils/useChartSeries';
import useEvaluatedChassisItems from './utils/useEvaluatedChassisItems';
import useEvaluatedTrucks from './utils/useEvaluatedTrucks';
import useHasTrucks from './utils/useHasTrucks';
import useRigidTrucks from './utils/useRigidTrucks';
import useTotalCount from './utils/useTotalCount';
import useTrailerTractors from './utils/useTrailerTractors';
import { dataGroups } from './dataGroups';
import { useEnhancedListVehicles } from '@optimization/sa-common';
import { useGetFactBasicDataQuery, useGetVehiclesQuery } from 'app/services/solution';

interface Props {
  solutionId: string;
}

const EvaluatedChassis = ({ solutionId }: Props) => {
  const factBasicDataQuery = useGetFactBasicDataQuery();
  const vehiclesQuery = useGetVehiclesQuery(solutionId);

  const enhancedListVehicles = useEnhancedListVehicles({
    vehicles: vehiclesQuery.data,
    factBasicDataQueryData: factBasicDataQuery.data,
  });

  const evaluatedTrucks = useEvaluatedTrucks(enhancedListVehicles);
  const hasTrucks = useHasTrucks(evaluatedTrucks);
  const rigidTrucks = useRigidTrucks(evaluatedTrucks);
  const trailerTractors = useTrailerTractors(evaluatedTrucks);

  const totalCount = useTotalCount({
    rigidTrucksCount: rigidTrucks.length,
    trailerTractorsCount: trailerTractors.length,
  });

  const items = useEvaluatedChassisItems({ hasTrucks, rigidTrucks, trailerTractors, totalCount });

  const chartSeries = useChartSeries({ evaluatedTrucks });

  return (
    <>
      <HelmetTitle solutionId={solutionId} items={['Evaluated chassis']} />
      {hasTrucks && (
        <>
          <InfoGrid className="mt-spacing-32">
            <ChassisAdaptationBox items={items} totalCount={totalCount} />
            {items.map((item) => (
              <CountBox key={item.header} header={item.header} subHeader="No. of chassis" count={item.count} />
            ))}
          </InfoGrid>
          <ChassisChart className="mt-spacing-40" chartSeries={chartSeries} />
          <StatisticsGrid className="mt-spacing-60">
            {dataGroups.map((dataGroup) => (
              <StatisticsTable key={dataGroup.name} chartSerie={chartSeries[dataGroup.name]} />
            ))}
          </StatisticsGrid>
        </>
      )}
      {!hasTrucks && vehiclesQuery.isSuccess && <div className="mt-spacing-32">Found no evaluated chassis</div>}
    </>
  );
};

export default EvaluatedChassis;
