import BottomDetail from './BottomDetail';
import BottomDetailNonExecuted from './BottomDetailNonExecuted';
import CandidateCharging from './CandidateCharging';
import classes from './style.module.scss';
import EnvironmentalImpactWrapper from './EnvironmentalImpactWrapper';
import ErrorBoundary from 'common/components/ErrorBoundary';
import FinancialEvaluation from './FinancialEvaluation';
import HistoricalStops from 'features/candidate/HistoricalStops';
import Menu, { MenuItemName } from './utils/Menu';
import SimulatedEnergyConsumption from './utils/SimulatedEnergyConsumption';
import useBatteryLife from './utils/useBatteryLife';
import useDefaultTabInitially from './utils/useDefaultTabInitially';
import useHandleOperationalFactors from './utils/useHandleOperationalFactors';
import useHandleVehicleConfiguration from './utils/useHandleVehicleConfiguration';
import useLoadingText from 'common/hooks/useLoadingText';
import useReadVehicleIntoFormState from './utils/useReadVehicleIntoFormState';
import useResetDepotYear from './utils/useResetDepotYear';
import useTabs from './utils/useTabs';
import useVehicleOptionsHandler from 'features/candidate/utils/vehicleOptionsHandler/useVehicleOptionsHandler';
import { CandidateTabs } from '.';
import { FINANCIAL_EVALUATION_ENABLED, MODE } from 'app/config';
import { InlineTabs, Loading, Tooltip } from '@optimization/ssi-common';
import { isUndefinedOrEmpty } from 'src/common/utils/helper';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDeleteChargingEventsMutation } from 'app/services/solution';
import {
  toListVehicle,
  useEnhancedVehicle,
  vehicleSettingsDefault,
  VehicleTransformed,
  RangeInfo,
  CandidateCreatedFrom,
  FactBasicDataResponse,
  Season,
  SetIsSoH80,
  SetIsWinter,
  SetShowTopUp,
  VehicleSettings,
  NET_EC_PARAM_DEFAULT,
} from '@optimization/sa-common';

export type FormState = {
  temperatureWinterC: string;
  temperatureSummerC: string;
  topography: string;
  traffic: string;
  avgSpeed: string;
  avgGtw: string;
  dailyOdometerMedian: string;
  cruisingSpeed: string;
  dailyOdometerLongest: string;
  runUpdateVehicle: boolean;
};

interface Props {
  className?: string;
  depotYear?: string;
  candidateTabDefault?: CandidateTabs;
  enableYearSelectorForChargingEvents: boolean;
  solutionId: string;
  tooltipKey: number;
  vehicle: VehicleTransformed;
  factBasicDataQueryData: FactBasicDataResponse;
  ptoDevices: any;
  vehicleSettings: VehicleSettings;
  setIsWinter: SetIsWinter;
  setIsSoH80: SetIsSoH80;
  setShowTopUp: SetShowTopUp;
}

const Main = ({
  className,
  depotYear: depotYearProp,
  candidateTabDefault = 'Vehicle configuration',
  enableYearSelectorForChargingEvents,
  solutionId,
  tooltipKey,
  vehicle,
  factBasicDataQueryData,
  ptoDevices,
  vehicleSettings,
  setIsWinter,
  setIsSoH80,
  setShowTopUp,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [activeMenuItemTitleName, setActiveMenuItemTitleName] = useState<MenuItemName>('Vehicle configuration');

  const [deleteChargingEvents, deleteChargingEventsState] = useDeleteChargingEventsMutation();

  const [depotYear, setDepotYear] = useState<string | undefined>(
    depotYearProp || vehicle.ExecutedVehicleProduct.PerformanceStep,
  );

  const [tabIndex, setTabIndex] = useState(0);

  const [formState, setFormState] = useState<FormState>({
    avgSpeed: '',
    avgGtw: '',
    cruisingSpeed: '',
    dailyOdometerMedian: '',
    dailyOdometerLongest: '',
    temperatureWinterC: '',
    temperatureSummerC: '',
    topography: '',
    traffic: '',
    runUpdateVehicle: false,
  });

  const season: Season = vehicleSettings.isWinter ? 'winter' : 'summer';

  const enhancedVehicle = useEnhancedVehicle({
    vehicle,
    vehicleSettings,
    depotYear,
    factBasicDataQueryData: factBasicDataQueryData,
  });

  const enhancedListVehicles = useMemo(() => [toListVehicle(enhancedVehicle)], [enhancedVehicle]);

  const vehicleOptionsHandler = useVehicleOptionsHandler({
    vehicles: enhancedListVehicles,
  });

  const updateOperationalFactorsState = useHandleOperationalFactors({
    formState,
    solutionId,
    vehicle,
    setFormState,
  });

  useReadVehicleIntoFormState({
    vehicle,
    setFormState,
  });

  const removeChargingEvents = useCallback(async () => {
    if (!enhancedVehicle.HomeDepotId) {
      return;
    }

    await deleteChargingEvents({
      solutionId,
      vehicleId: enhancedVehicle.Id,
    });
  }, [solutionId, enhancedVehicle, deleteChargingEvents]);

  const updateVehicleConfigurationState = useHandleVehicleConfiguration({
    solutionId,
    vehicle: enhancedVehicle,
    selections: vehicleOptionsHandler.selections,
    setSelections: vehicleOptionsHandler.setSelections,
  });

  useEffect(() => {
    if (depotYearProp) {
      setDepotYear(depotYearProp);
    }
  }, [depotYearProp]);

  const batteryLife = useBatteryLife(enhancedVehicle);

  const chargingEventTabRef = useRef<HTMLButtonElement>(null);
  const historicalStopsTabRef = useRef<HTMLButtonElement>(null);
  const enviromnetalImpactTabRef = useRef<HTMLButtonElement>(null);
  const chassisNumber = enhancedVehicle.ScaniaChassis?.ChassisNumber || 0;

  const mapLocation = useMemo(
    () => enhancedVehicle.MapLocationsV2?.filter((item) => item.Name === 'vehicle-candidate')?.[0],
    [enhancedVehicle],
  );

  const showHistorialStops = useMemo(() => Boolean(chassisNumber), [chassisNumber]);

  const tabs = useTabs({
    showHistorialStops,
    chargingEventTabRef,
    historicalStopsTabRef,
    enviromnetalImpactTabRef,
    vehicle: enhancedVehicle,
  });

  useDefaultTabInitially({
    tabs,
    vehicleId: enhancedVehicle.Id,
    candidateTabDefault,
    setTabIndex,
  });

  useResetDepotYear({
    tabs,
    tabIndex,
    vehicle: enhancedVehicle,
    enableYearSelectorForChargingEvents,
    setDepotYear,
  });

  const setIsWinterCallback = useCallback(
    (isWinter: boolean) => {
      setIsWinter({ vehicleId: vehicle.Id, isWinter });
    },
    [vehicle.Id, setIsWinter],
  );

  const setIsSoH80Callback = useCallback(
    (isSoH80: boolean) => {
      setIsSoH80({ vehicleId: vehicle.Id, isSoH80 });
    },
    [vehicle.Id, setIsSoH80],
  );

  const setShowTopUpCallback = useCallback(
    (showTopUp: boolean) => {
      setShowTopUp({ vehicleId: vehicle.Id, showTopUp });
    },
    [vehicle.Id, setShowTopUp],
  );

  useEffect(() => {
    if (tabIndex) {
      setIsWinterCallback(vehicleSettingsDefault.isWinter);
      setIsSoH80Callback(vehicleSettingsDefault.isSoH80);
      setShowTopUpCallback(vehicleSettingsDefault.showTopUp);
    }
  }, [tabIndex, setIsWinterCallback, setIsSoH80Callback, setShowTopUpCallback]);

  const netEc = enhancedVehicle.transformed.netEc[NET_EC_PARAM_DEFAULT][season];

  const cycledEnergyKWhKm = enhancedVehicle.transformed.cycledEnergy[NET_EC_PARAM_DEFAULT][season];

  const loadingText = useLoadingText({
    isUpdatingOperationFactors: updateOperationalFactorsState.isLoading,
    isUpdatingVehicleConfiguration: updateVehicleConfigurationState.isLoading,
    isDeletingChargingEvents: deleteChargingEventsState.isLoading,
  });

  const isLoading =
    updateOperationalFactorsState.isLoading ||
    updateVehicleConfigurationState.isLoading ||
    deleteChargingEventsState.isLoading;

  const isError =
    updateOperationalFactorsState.isError ||
    updateVehicleConfigurationState.isError ||
    deleteChargingEventsState.isError;

  return (
    <div className={className} data-testid="candidate-detail-container" ref={ref}>
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      <InlineTabs
        tabs={tabs}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        breakpoint="lg"
        extra={
          <CandidateCreatedFrom
            key={tooltipKey}
            mode={MODE}
            vehicleId={enhancedVehicle.Id}
            chassisNumber={enhancedVehicle.ScaniaChassis?.ChassisNumber}
            assemblyDateStr={enhancedVehicle.ScaniaChassis?.AssemblyDate}
            avgGtwPresentation={enhancedVehicle.transformed.avgGtwPresentation}
            chassisAdaptation={enhancedVehicle.ScaniaChassis?.ChassisAdaptation}
            axleDistanceMm={enhancedVehicle.ScaniaChassis?.AxleDistanceMm}
            chassisHeight={enhancedVehicle.ScaniaChassis?.ChassisHeight}
            cabType={enhancedVehicle.ScaniaChassis?.CabType}
            cabLength={enhancedVehicle.ScaniaChassis?.CabLength}
            chassisIdPresentationAlt={enhancedVehicle.transformed.chassisIdPresentationAlt}
            wheelConfiguration={enhancedVehicle.ScaniaChassis?.WheelConfiguration}
            bodyLength={enhancedVehicle.ScaniaChassis?.BodyLengthsM}
            floorHeight={enhancedVehicle.ScaniaChassis?.BusFloorHeight}
          />
        }
        extraClassName="inline-tabs-created-from"
      />
      {!enhancedVehicle.HomeDepotId && (
        <Tooltip elRef={chargingEventTabRef} key={`charging-event-${tooltipKey}`}>
          Please map the candidate to a home depot in order to define charging events.
        </Tooltip>
      )}
      {!!(vehicle.ScaniaChassis?.ChassisNumber && isUndefinedOrEmpty(vehicle.ScaniaChassis?.Consumption)) && (
        <Tooltip elRef={enviromnetalImpactTabRef} key={`environmental-impact-${tooltipKey}`}>
          Please re-evaluate chassis to understand environmental impact.
        </Tooltip>
      )}
      {tabs[tabIndex].name === 'Vehicle configuration' && (
        <ErrorBoundary>
          <RangeInfo
            className="mt-spacing-16"
            totEc={enhancedVehicle.enhanced.totEc}
            additionalRange={enhancedVehicle.enhanced.additionalRange}
            topUpRange={enhancedVehicle.enhanced.topUpRange}
            chargingDuration={enhancedVehicle.TopUpCharging?.ChargingDuration}
            chargingPowerKw={enhancedVehicle.TopUpCharging?.ChargingPowerKw}
            baseRange={enhancedVehicle.enhanced.baseRange}
            batteryLife={batteryLife}
            estimatedCycleEnergyPerKWhKm={cycledEnergyKWhKm}
            hasChargingEvents={enhancedVehicle.enhanced.hasChargingEvents}
            isSoH80={vehicleSettings.isSoH80}
            isWinter={vehicleSettings.isWinter}
            showTopUp={vehicleSettings.showTopUp}
            dailyDistance={enhancedVehicle.ExecutedVehicleProduct.DailyOdometerMedianKm}
            longestDailyDistance={enhancedVehicle.ExecutedVehicleProduct.DailyOdometerLongestKm}
            setIsSoH80={setIsSoH80Callback}
            setIsWinter={setIsWinterCallback}
            setShowTopUp={setShowTopUpCallback}
          />
          <div className="pt-spacing-12 px-spacing-16 pb-spacing-16">
            <div className={classes.bottom}>
              <div>
                <Menu
                  vehicle={enhancedVehicle}
                  activeMenuItemTitleName={activeMenuItemTitleName}
                  setActiveMenuItemTitleName={setActiveMenuItemTitleName}
                />
                <SimulatedEnergyConsumption
                  className="tds-u-mt3"
                  netEc={netEc}
                  vehicle={enhancedVehicle}
                  ptoDevices={ptoDevices}
                  tooltipKey={tooltipKey}
                  removeChargingEvents={removeChargingEvents}
                  isUpdateVehicleChargingEventsLoading={deleteChargingEventsState.isLoading}
                />
              </div>
              {enhancedVehicle.enhanced.basicVehicle ? (
                <BottomDetail
                  vehicle={enhancedVehicle}
                  activeMenuItemTitleName={activeMenuItemTitleName}
                  formState={formState}
                  vehicleOptionsHandler={vehicleOptionsHandler}
                  ptoDevices={ptoDevices}
                  setFormState={setFormState}
                />
              ) : (
                <BottomDetailNonExecuted solutionId={solutionId} vehicle={enhancedVehicle} />
              )}
            </div>
          </div>
        </ErrorBoundary>
      )}
      {tabs[tabIndex].name === 'Charging events' && (
        <ErrorBoundary>
          <CandidateCharging
            baseRange={enhancedVehicle.enhanced.baseRange}
            vehicle={enhancedVehicle}
            solutionId={solutionId}
            depotYear={depotYear}
            setDepotYear={setDepotYear}
            enableYearSelectorForChargingEvents={enableYearSelectorForChargingEvents}
          />
        </ErrorBoundary>
      )}
      {tabs[tabIndex].name === 'Historical stops' && (
        <ErrorBoundary>
          <HistoricalStops solutionId={solutionId} enhancedVehicles={enhancedListVehicles} mapLocation={mapLocation} />
        </ErrorBoundary>
      )}
      {tabs[tabIndex].name === 'Environmental impact' && (
        <ErrorBoundary>
          <EnvironmentalImpactWrapper vehicle={enhancedVehicle} solutionId={solutionId} />
        </ErrorBoundary>
      )}
      {tabs[tabIndex].name === 'Financial evaluation' && FINANCIAL_EVALUATION_ENABLED && (
        <ErrorBoundary>
          <FinancialEvaluation vehicle={enhancedVehicle} />
        </ErrorBoundary>
      )}
    </div>
  );
};

export default Main;
